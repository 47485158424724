
<div class="relative z-50 bg-dark" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div  #overlay [ngClass]="{
      'opacity-0 ease-out duration-300 hidden': !isOpen,
      'opacity-100 ease-in duration-200': isOpen
      }" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" (click)="onClose()"></div>
  <div #modal class="fixed inset-0 z-10 w-screen m-auto overflow-y-auto" [ngClass]="{
    'hidden': !isOpen
  }">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div [ngClass]="{
      'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95': !isOpen,
      'opacity-100 translate-y-0 sm:scale-100': isOpen
      }" class="relative transform overflow-hidden rounded-lg bg-dark px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
        <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block text-white">
          <button type="button" (click)="onClose()" class="rounded-md bg-dark text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span class="sr-only">Close</span>
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <section class="modal" >
          <ng-content></ng-content>
        </section>
      </div>
    </div>
  </div>
</div>
